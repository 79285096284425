import {
  createRouter,
  createWebHistory
} from 'vue-router'
import {
  magHeadermenu
} from '@/views/mag/router/index.js'

let magHeaderMenuToRouter=[]
magHeadermenu.forEach(e=>{
  e.children.forEach(c=>{
    magHeaderMenuToRouter.push(c)
  })
})
const routes = [{
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue')

  },
  {
    path: '/selectProject',
    name: 'selectProject',
    component: () => import('@/views/index/selectProject/index.vue')
  },
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/index/index.vue'),
    meta:{
      keepAlive: true,
      authority: {
        btn: 'batchRd',
        tableBtn: 'guangyuanOptionBox' 
  
      }
    }
    
  },
  {
    path: '/index2',
    name: 'index2',
    component: () => import('@/views/index/index2.vue'),
    meta:{
      authority: {
        btn: 'batchRd',
        tableBtn: 'guangyuanOptionBox' 
  
      }
    }
    
  },
  {
    path: '/baiduMap',
    name: 'baiduMap',
    component: () => import('@/views/index/baiduMap.vue'),
    meta:{
      authority: {
        btn: 'batchRd',
        tableBtn: 'guangyuanOptionBox' 
  
      }
    }
    
  },
  {
    path: '/threeStudy',
    name: 'threeStudy',
    component: () => import('@/views/index/threeStudy.vue'),
  },
  {
    path:'/logicflow',
    name:'logicflow',
    component: () => import('@/views/index/logicflow.vue'),
  },
  {
    path: '/mag',
    name: 'mag',
    component: () => import('@/views/mag/index.vue'),
    children: magHeaderMenuToRouter,
    meta: {
      leftMenuHidden: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
export default router