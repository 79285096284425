import {
    defineStore
} from "pinia";
import logo from '@/assets/img/login/LOGO.png'

const key=['KpjYZJpmTkDiTyZtUTfV5ueldHbtnocv','mye053boC3PkNN2CaXRdW2Gl2EuLpLNP']
function getRandomElement(arr) {
    var index = Math.floor(Math.random() * arr.length);
    return arr.splice(index, 1)[0];
}
const useSettingStore = defineStore('setting', {
    state: () => ({
        logo:null,
        // ak: getRandomElement(key),
		ak:'o2bEqH6dVenOvJ3NHNDRBhVcuRugeNf7',
        HeaderAct: 'guangyuan',
        // 告警信息-告警列表-点击操作按钮-获取行数据
        alarmInfoOperationData: null,
        //地图-扩大视图按钮
        expandMap: false,
        //切换应用
        selectProject:null,
        totalNumMarkerArr:null
    }),
    actions: {
        setTotalNumMarkerArr(val){
            this.totalNumMarkerArr=[]
            val.forEach(e => {
                this.totalNumMarkerArr.push(e)
            });
        },
        setSelectProject(val){
            this.selectProject=val
        },
        setExpandMap() {
            this.expandMap = !this.expandMap
            return this.expandMap
        },
        setHeaderAct(val) {
            this.HeaderAct = val
        },
        setAlarmInfoOperationData(val) {

            this.alarmInfoOperationData = val
        },
    }
})
export default useSettingStore