import {
  login,
  logout,
  getInfo
} from '@/api/login'
import useSettingStore from '@/store/modules/setting.js'
import {
  getToken,
  setToken,
  removeToken,
  getUserInfo,
  removeUserInfo
} from '@/utils/auth'
import {
  defineStore
} from "pinia";

const useUserStore = defineStore('user', {
  state: () => ({
    token: getToken(),
    info: getUserInfo()
  }),
  actions: {
    infoData() {
      const userInfo = localStorage.getItem('userInfo'); // 从 LocalStorage 获取用户信息
      if (userInfo == null) {
        return {};
      } else {
        let SelectProject = JSON.parse(sessionStorage.getItem('SelectProject'));
        return Object.assign(JSON.parse(userInfo), SelectProject);
      }
    },
    // 登录
    login(data) {
      const {
        username,
        password
      } = data;
      return new Promise((resolve, reject) => {
        login(username, password).then(res => {
          if (res === undefined) return;
          const {
            tokenType,
            token
          } = res.data;
          setToken({
            tokenType,
            token
          });
          this.token = {
            tokenType,
            token
          };
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },
    // 获取用户信息
    getInfo() {
      return new Promise((resolve, reject) => {
        getInfo().then(res => {
          if (res === undefined) return;
          this.info = {};
          if (res.data.level == '1') {
            // 存储用户信息到 LocalStorage
            localStorage.setItem("userInfo", JSON.stringify(res.data));
            resolve(res.data);
          } else {
            let SelectProject = JSON.parse(sessionStorage.getItem('SelectProject'));
            if (SelectProject) {
              // 合并项目信息和用户信息，存储到 LocalStorage
              const mergedInfo = Object.assign(SelectProject, res.data);
              localStorage.setItem('userInfo', JSON.stringify(mergedInfo));
              resolve(mergedInfo);
            } else {
              // 多应用跳转到 /selectProject
              if (location.pathname !== '/selectProject') {
                location.href = '/selectProject';
              }
            }
          }
        }).catch(error => {
          reject(error);
        });
      });
    },
    // 退出系统
    logOut() {
      return new Promise((resolve, reject) => {
        logout().then(res => {
          if (res === undefined) return;
          this.token = '';
          sessionStorage.removeItem("/mag/deviceController/index");
          removeToken();
          removeUserInfo();
          localStorage.removeItem('userInfo'); // 清除 LocalStorage 中的用户信息
          localStorage.setItem("logOutBeforeRouter", location.pathname + location.search);
          resolve();
        });
      });
    },
    logOut2() {
      return new Promise((resolve, reject) => {
        this.token = '';
        removeToken();
        removeUserInfo();
        localStorage.removeItem('userInfo'); // 清除 LocalStorage 中的用户信息
        localStorage.setItem("logOutBeforeRouter", location.pathname + location.search);
        resolve();
      });
    }
  }
});

export default useUserStore;